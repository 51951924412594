<template>
  <div class="signing-spinner">
    <div class="signing-spinner__circle">
      <div class="signing-spinner__bg" />
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="35" viewBox="0 0 65 35">
      <polygon points="1 35 54 35 54 30 1 30" />
      <polygon
        points="42.8344173 17.2939311 33.196959 7.15394375 23.7594481 17.1511948 14.1734048 7.1596532 0 21.9642631 4.7987344 27 14.1734048 16.9684923 23.7594481 26.9828716 23.7594481 27 33.2026718 16.9570734 42.8401301 26.9714527 42.8401301 27 65 5.08712201 60.2583934 0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SigningSpinner',
}
</script>

<style lang="sass">

@keyframes signing-spinner-rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.signing-spinner
  position: relative
  opacity: 0
  animation: fade-in .36s cubic-bezier(0,0,.2,1) .36s forwards

  &__circle
    position: relative
    width: 186px
    height: 186px
    border-radius: 93px
    background-image: linear-gradient(-269deg, #00CC81 0%, $c-primary 100%)
    animation: signing-spinner-rotate 1.2s linear infinite

  &__bg
    position: absolute
    left: 14px
    top: 14px
    width: 158px
    height: 158px
    border-radius: 79px
    background-color: $c-white

  svg
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    margin-right: -7px
    fill: $c-grey-darker
</style>
